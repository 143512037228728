
<template>
  <v-app>
    <v-container class="fill-height bg-login" fill-height fluid>
      <v-row align="center" justify="end" class="mr-4 ml-4">
        <v-col cols="12" sm="8" md="4">
          <v-card class>
            <div class="pt-4 pb-4">
              <img src="../../assets/intract_logo.png" width="120" />
              <h3>Welcome to Fees Portal</h3>
            </div>

            <v-divider class="mx-4"></v-divider>

            <v-form @submit="checkInstitutionCode">
              <v-card-text>
                <div class="pl-4 pr-4" data-cy="code-text-field-container">
                  <p v-if="formErrors.genError" class="form-error">
                    {{ formErrors.genError }}
                  </p>
                  <h4 class="text-left mb-3">Enter Institution Code</h4>
                  <v-text-field
                    v-model="code"
                    :error="formErrors.codeError != null"
                    :error-messages="formErrors.codeError"
                    type="text"
                    outlined
                    dense
                    rounded
                    data-cy="institute-code-text-field"
                  ></v-text-field>
                </div>
                <div class="text-center">
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    size="30"
                  ></v-progress-circular>
                  <div class="mr-3 ml-3 pb-4" v-else>
                    <v-btn
                      data-cy="login-institute-code-submit-button"
                      color="primary"
                      type="submit"
                      block
                      >Submit</v-btn
                    >
                  </div>
                </div>
              </v-card-text>
              <v-divider class="ma-4"></v-divider>
              <h5>Visit our other products</h5>
            </v-form>
            <v-row class="pa-5" align="center" justify="center">
              <v-btn
                icon
                href="https://play.google.com/store/apps/details?id=com.intract.ifi"
                class="mx-2"
                ><v-icon large>mdi-google-play</v-icon>
              </v-btn>

              <v-btn
                icon
                href="https://apps.apple.com/in/app/intract-for-institutes/id1513759986"
                class="mx-2"
                ><v-icon large>mdi-apple</v-icon>
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar v-model="snackbar.visible">
        {{ snackbar.text }}
        <template>
          <v-btn color="pink" text @click="snackbar.visible = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import api from "@api/index";
import endpoints from "@api/repository";

// import ApiError from '@utils/helper_classes';
export default {
  name: "LoginInstitute",
  data() {
    return {
      code: "",
      formErrors: {
        genError: null,
        codeError: null,
      },
      snackbar: {
        visible: false,
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isLoading", "currentUser"]),
    apiEssentials() {
      return {
        accessToken: "",
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    // checks for any invalid or empty field
    validateFields() {
      this.code = this.code.trim();
      this.formErrors.codeError = null;
      if (!this.code) {
        this.formErrors.codeError = "This field is required";
        return false;
      }
      return true;
    },

    async checkInstitutionCode(e) {
      e.preventDefault();
      if (!this.validateFields()) return false;
      this.setLoading(true);

      // send request to server if code not empty
      var response;
      response = await api.handleApi({
        apiEssentials: this.apiEssentials,
        url: endpoints.checkInstitutionCode + this.code + "/",
        responseVar: response,
      });

      if (response) {
        this.$router.push({ name: "LoginUser", params: { code: this.code } });
        return;
      }
      this.formErrors.codeError = "Invalid Code";
      console.log(response);
      this.setLoading(false);
    },
    handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        console.log(err.response);
      }
    },
  },
  created() {
    // if user on login page and currentUser != null, navigate to home '/'
    // this.setLoading(false);
    if (this.currentUser != null) this.$router.push("/");
  },
};
</script>

<style scoped>
.bg-login {
  background-image: url("../../assets/bg_login.png") !important;
  background-size: cover;
}
.bottom-promotional-bar {
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 100%;
  -webkit-box-shadow: 2px 3px 24px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 3px 24px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 3px 24px 2px rgba(0, 0, 0, 0.75);
}
</style>